<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Regions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "List Of Region",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Regions",
          active: true
        }
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      fields: [
        'No',
        { key: "name", label: "Name", sortable: true },
        { key: "created_at", label: "Created At", sortable: true },
        'Action'
      ],
      deleteId: null
    };
  },
  methods: {
    async fetchRegions(ctx) {
      console.log(ctx)
      const promise = this.$axios.get(`region`, {
        params: {
          page: ctx.currentPage,
          per_page: ctx.perPage,
          search: ctx.filter,
          sortBy: ctx.sortBy,
          sortType: (ctx.sortDesc)? 'asc' : 'desc'
        }
      })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        const items = response.data.data.data
        this.totalRows = response.data.data.total
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    confirmDelete(regionId) {
      this.deleteId = regionId
      this.$bvModal.show('modal-delete')
    },
    deleteData() {
      let param = new FormData();
      param.append('_method', 'delete')
      param.append('id', this.deleteId)
      this.$axios.post(`region`, param)
        .then( response => {
          console.log(response.data)
          Swal.fire("Success", "Region Deleted Successfully", "success");
          this.$refs.table.refresh();
        }).catch( error => {
          console.log(error.response)
          Swal.fire("Error", error.response.data.message, "error");
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :action="{ title: 'Create Region', link: '/region/create'}" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="table"
                :items="fetchRegions"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :empty-text="'There are no records to show'"
                :empty-filtered-text="'There are no records matching your request'"
              >
                <!-- A virtual column -->
                <template v-slot:cell(No)="data">
                  {{ data.index + 1 }}
                </template>
                <!-- Created At -->
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | date_time }}
                </template>
                <!-- Aciton -->
                <template v-slot:cell(action)="data">
                  <b-button variant="outline-primary" size="sm" :to="`/region/edit/${data.item.id}`">Edit</b-button> &nbsp;
                  <b-button variant="outline-danger" size="sm" @click.prevent="confirmDelete(data.item.id)">Delete</b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-delete"
      centered
      title="Warning!"
      title-class="font-18"
      @cancel="deleteId = null"
      @ok="deleteData"
    >
      <p>are you sure you want to delete this data ?</p>
    </b-modal>
  </Layout>
</template>